import React from "react";
import { Routes, Route } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Loading from "./components/loading";
import Layout from "./components/layout/layout";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import GenericPrivacyPolicy from "./pages/GenericPrivacyPolicy";

/*pages*/
const User = React.lazy(() => import('./pages/User'));
const Login = React.lazy(() => import('./pages/Login'));
const LoginSupport = React.lazy(() => import('./pages/LoginSupport'));
const Register = React.lazy(() => import('./pages/Register'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'))
const Invitations = React.lazy(() => import('./pages/Invitations'))
const PageNotFound = React.lazy(() => import('./pages/PageNotFound'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Chat = React.lazy(() => import('./pages/Chat'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const BatchActions = React.lazy(() => import('./pages/Contacts/BatchActions'));
const Csv = React.lazy(() => import('./pages/Csv'));
const Flows = React.lazy(() => import('./pages/Flows'));
const Example = React.lazy(() => import('./pages/Example'));
const Metrics = React.lazy(() => import('./pages/Metrics'));
const PipelineStepIndicators = React.lazy(() => import('./pages/Metrics/PipelineStepIndicators'));
const ContactsIndicators = React.lazy(() => import('./pages/Metrics/ContactsIndicators'));
const FlowSteps = React.lazy(() => import('./pages/Metrics/FlowSteps'));
const PipelineEngagement = React.lazy(() => import('./pages/Metrics/PipelineEngagement'));
const UserPendings = React.lazy(() => import('./pages/Metrics/UserPendings'));
const Account = React.lazy(() => import('./pages/Settings/Account'));
const HeatMap = React.lazy(() => import('./pages/Metrics/HeatMap'));
const FlowContacts = React.lazy(() => import('./pages/Metrics/FlowContacts'));
const LimitsAndUtilization = React.lazy(() => import('./pages/Metrics/LimitsAndUtilization'));
const WhatsappNumbersQuality = React.lazy(() => import('./pages/Metrics/WhatsappNumbersQuality'));
const EmailChannel = React.lazy(() => import('./pages/Settings/EmailChannel'));
const CataclienteChannel = React.lazy(() => import('./pages/Settings/CataclienteChannel'));
const WhatsappChannel = React.lazy(() => import('./pages/Settings/WhatsappChannel'));
const Pipeline = React.lazy(() => import('./pages/Settings/Pipeline'));
const ProductsAndServices = React.lazy(() => import('./pages/Settings/ProductsAndServices'));
const EmailVerification = React.lazy(() => import('./pages/EmailVerification'));
const UserVerify = React.lazy(() => import('./pages/UserVerify'));
const WhiteLabel = React.lazy(() => import('./pages/Settings/WhiteLabel'));
const CustomFields = React.lazy(() => import('./pages/Settings/CustomFields'));
const ContactSources = React.lazy(() => import('./pages/Settings/ContactSources'));
const WebForms = React.lazy(() => import('./pages/WebForms'));
const WebFormsSettings = React.lazy(() => import('./pages/Settings/WebForms'));
const Referral = React.lazy(() => import('./pages/Referral'));
const Calendar = React.lazy(() => import('./pages/Settings/Calendar'));
const ScheduledEvents = React.lazy(() => import('./pages/ScheduledEvents'))
const InvalidSubscription = React.lazy(() => import("./pages/InvalidSubscription"));
const Schedule = React.lazy(() => import('./pages/Settings/Calendar/components/schedule'));
const SubscriptionCompanyData = React.lazy(() => import('./pages/Settings/Subscriptions/CompanyData'));
const SubscriptionCardInfo = React.lazy(() => import('./pages/Settings/Subscriptions/CardInfo'));
const SubscriptionEmail = React.lazy(() => import('./pages/Settings/Subscriptions/Email'));
const SubscriptionCharge = React.lazy(() => import('./pages/Settings/Subscriptions/Charge'));
const SubscriptionLimitsAndUtilization = React.lazy(() => import('./pages/Settings/Subscriptions/LimitsAndUtilization'));
const IntegrationsWebhook = React.lazy(() => import('./pages/Settings/Integrations/Webhook'));

/* const ExampleFunnel = React.lazy(() => import('./pages/Example/funnel')); */
/*pages*/

const AppRoutes = () => {
    const Private = ({ Item }) => {
        const { signed, verified, subscription_expired } = useAuth();

        return signed > 0 ? (
            !verified ? (
                <UserVerify />
            ) : subscription_expired ? (
                <InvalidSubscription />
            ) : (
                <Layout>
                    <React.Suspense fallback={<Loading />}>
                        <Item />
                    </React.Suspense>
                </Layout>
            )
        ) : (
            <Login />
        );
    };

    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/login/support" element={<LoginSupport />} />
            <Route exact path="/register/:gateway?" element={<Register />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/reset-password/:token/:email" element={<ResetPassword />} />
            <Route exact path="/invitations/:token" element={<Invitations />} />
            <Route exact path="/web-forms/:token" element={<WebForms />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/generic-privacy-policy" element={<GenericPrivacyPolicy />} />
            <Route exact path="/calendar/:token/schedule" element={<Schedule />} />

            <Route exact path="/chat" element={<Private Item={Chat} />} />
            <Route exact path="/chat/:id" element={<Private Item={Chat} />} />
            <Route exact path="/user/:id" element={<Private Item={User} />} />

            <Route exact path="/contacts" element={<Private Item={Contacts} />} />
            <Route exact path="/contacts/people" element={<Private Item={Contacts} />} />
            <Route exact path="/contacts/people/:id" element={<Private Item={Contacts} />} />
            <Route exact path="/contacts/companies" element={<Private Item={Contacts} />} />
            <Route exact path="/contacts/companies/:id" element={<Private Item={Contacts} />} />
            <Route exact path="/contacts/batch-actions" element={<Private Item={BatchActions} />} />

            <Route exact path="/contacts/import" element={<Private Item={Csv} />} />
            <Route exact path="/contacts/import/:id" element={<Private Item={Csv} />} />

            <Route exact path="/flows" element={<Private Item={Flows} />} />
            <Route exact path="/flows/:id" element={<Private Item={Flows} />} />

            <Route exact path="/examples" element={<Private Item={Example} />} />

            {/* <Route exact path="/examples/funnel" element={<Private Item={ExampleFunnel} />} /> */}

            <Route exact path="settings" element={<Private Item={Settings} />} />
            <Route exact path="/settings/subscriptions/company-data" element={<Private Item={SubscriptionCompanyData} />} />
            <Route exact path="/settings/subscriptions/card-info" element={<Private Item={SubscriptionCardInfo} />} />
            <Route exact path="/settings/subscriptions/email" element={<Private Item={SubscriptionEmail} />} />
            <Route exact path="/settings/subscriptions/charges" element={<Private Item={SubscriptionCharge} />} />
            <Route exact path="/settings/subscriptions/limits-and-utilizations" element={<Private Item={SubscriptionLimitsAndUtilization} />} />
            <Route exact path="/settings/account" element={<Private Item={Account} />} />
            <Route exact path="/settings/accounts" element={<Private Item={Account} />} />
            <Route exact path="/settings/accounts/:userIdParam" element={<Private Item={Account} />} />
            <Route exact path="/settings/channels/email" element={<Private Item={EmailChannel} />} />
            <Route exact path="/settings/channels/whatsapp" element={<Private Item={WhatsappChannel} />} />
            <Route exact path="/settings/channels/web-forms" element={<Private Item={WebFormsSettings} />} />
            <Route exact path="/settings/channels/web-forms/:id" element={<Private Item={WebFormsSettings} />} />
            <Route exact path="/settings/channels/linkedin" element={<Private Item={CataclienteChannel} />} />
            <Route exact path="/settings/integrations/webhook" element={<Private Item={IntegrationsWebhook} />} />
            <Route exact path="/settings/pipeline" element={<Private Item={Pipeline} />} />
            <Route exact path="/settings/products-services" element={<Private Item={ProductsAndServices} />} />
            <Route exact path="/settings/white-label" element={<Private Item={WhiteLabel} />} />
            <Route exact path="/settings/custom-fields" element={<Private Item={CustomFields} />} />
            <Route exact path="/settings/contact-sources" element={<Private Item={ContactSources} />} />

            <Route exact path="/metrics" element={<Private Item={Metrics} />} />
            <Route exact path="/metrics/pipeline-step-indicators" element={<Private Item={PipelineStepIndicators} />} />
            <Route exact path="/metrics/contacts-indicators" element={<Private Item={ContactsIndicators} />} />
            <Route exact path="/metrics/flow-steps" element={<Private Item={FlowSteps} />} />
            <Route exact path="/metrics/pipeline-step-indicators/:pipelineStepId/step/:stepId" element={<Private Item={PipelineStepIndicators} />} />
            <Route exact path="/metrics/pipeline-engagement" element={<Private Item={PipelineEngagement} />} />
            <Route exact path="/metrics/user-pendings" element={<Private Item={UserPendings} />} />
            <Route exact path="/metrics/heat-map" element={<Private Item={HeatMap} />} />
            <Route exact path="/metrics/flow-contacts" element={<Private Item={FlowContacts} />} />
            <Route exact path="/metrics/limits-and-utilization" element={<Private Item={LimitsAndUtilization} />} />
            <Route exact path="/metrics/whatsapp-numbers-quality" element={<Private Item={WhatsappNumbersQuality} />} />

            <Route exact path="/settings/calendar" element={<Private Item={Calendar} />} />
            <Route exact path="/settings/calendar/events/:id" element={<Private Item={Calendar} />} />

            <Route exact path="/email-verification" element={<Private Item={EmailVerification} />} />
            <Route exact path="/referral" element={<Private Item={Referral} />} />
            <Route exact path="/scheduled-events" element={<Private Item={ScheduledEvents} />} />

            <Route exact path="/examples" element={<Private Item={Example} />} />

            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default AppRoutes;
